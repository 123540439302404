<template>
    <MainFunctions :buttons="buttons" :title="$t('menu.nav-storehome-title')"></MainFunctions>
</template>

<script>
import store from '@/store'
import MainFunctions from "@/components/MainFunctions"
import { HOMETYPES } from '@/utils/utils'

export default {
    name: 'StoreHostMenu',
    components: {
        MainFunctions
    },
    data() {
        store.commit('setPageHome', HOMETYPES.STORE)
        return {
            buttons: [
                { title: 'menu.buttons.goods', color: "cyan", icon: 'store', routeName: 'storehostPutin'},
                { title: 'menu.buttons.goods-retrieve', color: "purple darken-2", icon: 'cleaning_services', routeName: 'storehostRetrieve'}
            ]
        }
    },
    activated() {
        store.commit('setPageHome', HOMETYPES.STORE)
    }
}
</script>
